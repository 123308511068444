import dayjs from 'dayjs';

export const useDate = () => {
  const formatDate = (date: string) => {
    return dayjs(date).format('DD.MM.YYYY');
  }

  const formatDateWithoutYear = (date: string) => {
    return dayjs(date).format('DD.MM');
  }

  const formatDateWithHour = (date: string) => {
    return dayjs(date).format('DD.MM.YYYY HH:mm');
  }

  const formatDateWithSeconds = (date: string) => {
    return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
  }

  const onlyHours = (date: string) => {
    return dayjs(date).format('HH:mm:ss');
  }

  const onlyHoursWithoutSeconds = (date: string) => {
    return dayjs(date).format('HH:mm');
  }

  return {
    formatDate,
    formatDateWithoutYear,
    formatDateWithHour,
    formatDateWithSeconds,
    onlyHours,
    onlyHoursWithoutSeconds
  }
}
